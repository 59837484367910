<template>
	<div
		class="flex-between-icenter w-full py-4 px-4 sm:px-20"
		:class="[props.absolute && 'absolute top-0 left-0 right-0', props.transparent ? 'bg-transparent' : 'bg-white']"
	>
		<NuxtLink>
			<img class="h-[50px]" src="../../assets/logo/logo.png" alt="logo" @click="navigateTo('/')" />
		</NuxtLink>

		<!-- DESKTOP -->
		<div class="hidden sm:flex">
			<UButton
				class="px-5 h-[50px]"
				variant="link"
				size="xl"
				:ui="{
					rounded: 'rounded-none',
					variant: { outline: `ring-2 ${props.transparent ? 'ring-white' : 'ring-wemotooBlue-350'}` },
				}"
				@click="navigateTo('/merchant-nearby')"
			>
				<p :class="['bg-transparent font-jakarta text-base mt-1', props.transparent ? 'text-white' : 'text-wemotooBlue-350']">Merchant Nearby</p>
			</UButton>

			<!-- <UButton
				class="px-5 h-[50px]"
				variant="link"
				size="xl"
				:ui="{
					rounded: 'rounded-none',
					variant: { outline: `ring-2 ${props.transparent ? 'ring-white' : 'ring-wemotooBlue-350'}` },
				}"
				@click="navigateTo('/knowledge')"
			>
				<p :class="['bg-transparent font-jakarta text-base mt-1', props.transparent ? 'text-white' : 'text-wemotooBlue-350']">Knowledge Center</p>
			</UButton> -->

			<UButton
				class="px-5 h-[50px] mr-4"
				variant="link"
				size="xl"
				:ui="{
					rounded: 'rounded-none',
					variant: { outline: `ring-2 ${props.transparent ? 'ring-white' : 'ring-wemotooBlue-350'}` },
				}"
				@click="navigateTo('/about-us')"
			>
				<p :class="['bg-transparent font-jakarta text-base mt-1', props.transparent ? 'text-white' : 'text-wemotooBlue-350']">About Us</p>
			</UButton>

			<ClientOnly>
				<UButton
					v-if="!user"
					class="px-5 h-[50px]"
					variant="outline"
					size="xl"
					:ui="{
						rounded: 'rounded-none',
						variant: { outline: `ring-2 ${props.transparent ? 'ring-white' : 'ring-wemotooBlue-350'}` },
					}"
					@click="navigateTo('/login')"
				>
					<p :class="['bg-transparent font-america text-xl mt-1', props.transparent ? 'text-white' : 'text-wemotooBlue-350']">LOGIN / SIGN UP</p>
				</UButton>

				<UDropdown
					v-if="user"
					:items="items"
					:popper="{ placement: 'bottom' }"
					:ui="{
						rounded: 'rounded-none',
						ring: 'ring-0',
						base: 'relative focus:outline-none overflow-y-auto scroll-py-1',
						padding: 'p-0',
						item: {
							base: 'group flex items-center gap-1.5 w-full font-jakarta',
							rounded: 'rounded-none',
							padding: 'px-4 py-4',
							size: 'text-base',
							active: 'bg-wemotooPrimary text-white',
							inactive: 'text-neutral-50 dark:text-gray-200',
							disabled: 'cursor-not-allowed opacity-50',
						},
					}"
				>
					<UButton
						class="px-4 sm:px-6 h-[50px]"
						variant="outline"
						size="xl"
						:ui="{
							rounded: 'rounded-none',
							variant: { outline: `ring-2 ${props.transparent ? 'ring-white' : 'ring-wemotooBlue-350'}` },
						}"
					>
						<div class="flex justify-around items-center">
							<UIcon name="i-material-symbols-person-rounded" :class="['size-6', props.transparent ? 'text-white' : 'text-wemotooBlue-350']" />
							<p :class="['bg-transparent font-america text-xl mt-1 ml-2', props.transparent ? 'text-white' : 'text-wemotooBlue-350']">
								{{ user.name }}
							</p>
						</div>
					</UButton>
				</UDropdown>
			</ClientOnly>
		</div>

		<!-- DESKTOP -->

		<!-- MOBILE -->
		<UButton variant="ghost" size="xl" class="sm:hidden" @click="toggleSidebar">
			<UIcon name="i-material-symbols-menu" :class="['size-6', props.transparent ? 'text-white' : 'text-wemotooBlue']" />
		</UButton>

		<USlideover v-model="isSidebarOpen" :ui="{ overlay: { background: 'bg-wemotooBlack-600/70' } }">
			<UCard
				class="flex flex-col flex-1"
				:ui="{
					wrapper: 'fixed inset-0 flex z-[1001]',
					header: {
						base: 'border-b border-gray-300',
						padding: 'py-0 px-0 sm:px-0 pt-4',
					},
					body: { base: 'flex-1', padding: 'py-0 px-0 sm:px-0' },
					ring: '',
					divide: '',
				}"
			>
				<template #header>
					<div class="flex justify-end items-end">
						<UButton color="black" variant="ghost" size="xl" icon="i-heroicons-x-mark-solid" square padded @click="toggleSidebar" />
					</div>
				</template>

				<div class="flex flex-col text-wemotooBlue-350 divide-y divide-gray-300">
					<div v-if="!user" class="flex-between-icenter px-6 py-3" @click="navigateTo('/login')">
						<div class="flex items-center gap-2">
							<div class="bg-wemotooBlue-350 bg-opacity-15 size-10 rounded-full flex-center">
								<UIcon name="i-material-symbols-person-rounded" class="size-6" />
							</div>
							<p class="font-america text-xl mt-1">HI, GUEST!</p>
						</div>

						<p class="font-jakarta text-sm text-wemotooBlue-350 font-semibold">Login</p>
					</div>

					<div v-else class="flex-between-icenter bg-transparent px-6 py-3" @click="navigateTo('/user/profile')">
						<div class="flex items-center gap-2">
							<div class="bg-wemotooBlue-350 bg-opacity-15 size-10 rounded-full flex-center">
								<UIcon name="i-material-symbols-person-rounded" class="size-6" />
							</div>
							<p class="font-america text-xl mt-1">{{ user.name }}</p>
						</div>

						<img src="../../assets/svg/edit.svg" />
					</div>

					<div v-if="user" class="section space-y-3">
						<h1>Account</h1>
						<h3><NuxtLink to="/user/events">My Events</NuxtLink></h3>
						<h3><NuxtLink to="/user/payment-history">Payment History</NuxtLink></h3>
					</div>

					<div class="section space-y-3">
						<h1>Wemotoo</h1>
						<!-- <h3><NuxtLink to="/knowledge">Knowledge Center</NuxtLink></h3> -->
						<h3><NuxtLink to="/merchant-nearby">Merchant Nearby</NuxtLink></h3>
						<h3><NuxtLink to="/about-us">About Us</NuxtLink></h3>
					</div>
				</div>

				<template #footer>
					<div v-if="user">
						<UButton
							variant="ghost"
							size="sm"
							class="sm:hidden"
							:ui="{
								padding: {
									'2xs': 'px-0 py-1',
									'xs': 'px-0 py-1.5',
									'sm': 'px-0 py-1.5',
								},
							}"
							@click="logout()"
						>
							<img src="../../assets/svg/logout.svg" />
							<span class="font-jakarta text-sm font-medium text-systemRed">Logout</span>
						</UButton>
					</div>
				</template>
			</UCard>
		</USlideover>
		<!-- MOBILE -->
	</div>
</template>

<script lang="ts" setup>
// define props
const props = defineProps<{
	absolute?: boolean;
	transparent?: boolean;
}>();

const isSidebarOpen = ref(false);
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const items = [
	[
		{
			label: 'My Events',
			class: 'border-b border-neutral-30',
			click: () => navigateTo('/user/events'),
		},
		{
			label: 'Payment History',
			class: 'border-b border-neutral-30',
			click: () => navigateTo('/user/payment-history'),
		},
		{
			label: 'Profile',
			class: 'border-b border-neutral-30',
			click: () => navigateTo('/user/profile'),
		},
		{
			label: 'Logout',
			class: 'text-systemRed hover:text-white active:text-white',
			click: () => {
				const authStore = useAuthStore();
				authStore.logout();
			},
		},
	],
];

const toggleSidebar = () => {
	isSidebarOpen.value = !isSidebarOpen.value;
};

const logout = () => {
	const authStore = useAuthStore();
	authStore.logout();
};
</script>

<style scoped lang="css">
h1 {
	@apply font-jakarta text-wemotooBlue font-semibold text-sm;
}

h3 {
	@apply font-jakarta text-neutral-50 text-sm;
}

.section {
	@apply px-6 py-4;
}
</style>
